// src/App.js
import React from 'react';
import { RouterProvider } from 'react-router-dom';
import router from './Components/router';
import './Styles/App.css'; // Ensure this import is correct

function App() {
  return (
    <main>
      <RouterProvider router={router} />
    </main>
  );
}

export default App;
