import React from 'react';
import "../Styles/Contact.css"; // Make sure to import the CSS file

const Contact = () => {
  return (
    <div className="contact-container">
      {/* <h2 className="contact-title">SEE YOU THERE!</h2> */}
      <p className="contact-address">346 Hubbard Rd, Galloway, OH 43119</p>
      <p className="contact-phone">(614)385-4336</p>
    </div>
  );
};

export default Contact;
