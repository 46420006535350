import React from "react";
import "../Styles/Footer.css"
const Footer = () => {
    return(
        <footer className="footer">
                <p>&copy; 2024 RomanMathDev. All rights reserved.</p>
        </footer>
    );
};

export default Footer;